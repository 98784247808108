const message = {
  USER__VERIFY_KEY__BLOCK:
    'Quá 3 request trong 1 phút, vui lòng thử lại sau 3 phút',
  USER__ACCOUNT__INCORRECT: 'Tài khoản hoặc mật khẩu không đúng.',
  STUDENT__DELETE__SUCCESS: 'Xóa thành công',
  USER__PHONE_NUMBER__REQUIRED: 'Vui lòng nhập số điện thoại.',
  USER__PHONE_NUMBER__INVALID: 'Số điện thoại không tồn tại trên hệ thống.',
  USER__PHONE_NUMBER__INCORRECT: 'Số điện thoại chưa được xác nhận',
  USER__VERIFY_KEY__SENDING: 'Gửi mã xác minh thành công',
  USER__CREATE__SUCCESS: 'Đăng ký thành công',
  USER__PASSWORD__INVALID: 'Mật khẩu không đúng ',
  USER__EMAIL__INVALID: 'Vui lòng nhập đúng địa chỉ Email.',
  USER__ACCOUNT__DEACTIVATE: 'Tài khoản đã bị vô hiệu hóa.',
  USER__UPDATE__SUCCESS: 'Cập nhật thành công',
  USER__VERIFY_KEY__EXPIRED: 'Mã xác thực hết hạn! Vui lòng nhập mã khác.',
  USER__VERIFY_KEY__INCORRECT: 'Mã xác thực không đúng! Vui lòng nhập mã khác.',
  USER__AVATAR__REQUIRED: 'Vui lòng chọn avata',
  ASSETS__DELETE__SUCCESS: 'Xóa file thành công.',
  BOOK__CREATE__SUCCESS: 'Thêm sách thành công.',
  BOOK__UPDATE__SUCCESS: 'Sửa sách thành công.',
  USER__PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu',
  USER__PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu',
  USER__PHONE_NUMBER__UNIQUE: 'Số điện thoại đã tồn tại',
  USER__PHONE_NUMBER__EMPTY: 'Số điện thoại đang để trống',
  USER__EMAIL__REQUIRED: 'Vui lòng nhập Email',
  USER__EMAIL__LENGTH: 'Độ dài Email không được quá 52 ký tự',
  USER__EMAIL__UNIQUE: 'Email đã tồn tại',
  USER__EMAIL__EMPTY: 'Vui lòng không để trống Email',
  USER__BIRTHDAY__INVALID_FORMAT: 'Sai định dạng ngày',
  USER__OLD_PASSWORD__EMPTY: 'Vui lòng không để trống nhập lại mật khẩu',
  USER__OLD_PASSWORD__REQUIRED: 'Vui lòng nhập lại mật khẩu',
  USER__NEW_PASSWORD__EMPTY: 'Vui lòng không để trống mật khẩu mới',
  USER__NEW_PASSWORD__REQUIRED: 'Vui lòng nhập mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__EMPTY:
    'Vui lòng không để trống trường nhập lại mật khẩu mới',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Vui lòng nhập lại mật khẩu mới',
  USER__SUID__INVALID: 'Suid không hợp lệ',
  USER__FIELD__UNIQUE: 'Dữ liệu đã tồn tại trong hệ thống',
  ASSETS__RESOURCE_TYPE__EMPTY: 'Loại tệp tin đang để trống',
  ASSETS__RESOURCE_TYPE__REQUIRED: 'Vui lòng nhập loại tập tin',
  ASSETS__RESOURCE_TYPE__INVALID: 'Loại tập tin không hợp lệ',
  ASSETS__NAME__EMPTY: 'Tên tập tin đang để trống',
  ASSETS__NAME__REQUIRED: 'Vui lòng nhập tên tập tin',
  ASSETS__TAGS__EMPTY: 'Tên thẻ đang để trống',
  ASSETS__TAGS__REQUIRED: 'Vui lòng nhập thẻ',
  BOOK__NAME__EMPTY: 'Tên sách đang để trống',
  BOOK__NAME__REQUIRED: 'Vui lòng nhập tên sách',
  BOOK__ASSETS_ID__INVALID: 'ID tập tin không đúng',
  BOOK__USER_PROFILE_ID__INVALID: 'ID hồ sơ người dùng không đúng',
  COURSE__NAME__EMPTY: 'Tên khóa học đang để trống',
  COURSE__NAME__REQUIRED: 'Vui lòng nhập tên khóa học',
  COURSE__NAME__UNIQUE: 'Tên khóa học đã tồn tại',
  COURSE__ASSETS_ID__INVALID: 'ID khóa học không tồn tại',
  COURSE__STUDENT_ID__INVALID: 'ID học sinh không tồn tại',
  WORKSPACE__NAME__EMPTY: 'Tên workspace đang để trống',
  WORKSPACE__NAME__REQUIRED: 'Vui lòng tên workspace',
  WORKSPACE__NAME__UNIQUE: 'Tên workspace đã tồn tại',
  WORKSPACE__WORKSPACE__UNIQUE: 'Workspace đã tồn tại',
  WORKSPACE__WORKSPACE__EMPTY: 'Workspace đang để trống',
  WORKSPACE__WORKSPACE__REQUIRED: 'Vui lòng nhập trường workspace',
  WORKSPACE__CREATE__FAILED: 'Tạo Workspace thất bại',
  USER_PROFILE__DOB__INVALID_FORMAT: 'Sai định dạng ngày sinh',
  STUDENT__DOB__INVALID_FORMAT: 'Sai định dạng ngày sinh',
  INVITATION_CODE__BOOK_ID__INVALID: 'Sai mã sách',
  INVITATION_CODE__COURSE_ID__INVALID: 'Sai mã khóa học',
  INVITATION_CODE__DELETE__SUCCESS: 'Xóa thành công',
  INVITATION_CODE__CREATE__SUCCESS: 'Tạo thành công',
  STUDENT__UPDATE__SUCCESS: 'Cập nhật thành công',
  STUDENT__CREATE__SUCCESS: 'Tạo thành công',
  USER_PROFILE__UPDATE__SUCCESS: 'Cập nhật thành công',
  WORKSPACE__DELETE__SUCCESS: 'Xóa thành công',
  WORKSPACE__UPDATE__SUCCESS: 'Cập nhật thành công',
  WORKSPACE__CREATE__SUCCESS: 'Tạo thành công',
  COURSE__ADD_STUDENT__SUCCESS: 'Thêm thành công',
  COURSE__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  BOOK__DELETE__SUCCESS: 'Xóa thành công',
  COURSE__UPDATE__SUCCESS: 'Cập nhật thành công',
  COURSE__CREATE__SUCCESS: 'Tạo thành công',
  BOOK__INVITE_USER__SUCCESS: 'Thêm thành công',
  BOOK__ADD_ASSETS__SUCCESS: 'Thêm thành công',
  ASSETS__UPDATE__SUCCESS: 'Cập nhật thành công',
  ASSETS__CREATE__SUCCESS: 'Tạo thành công',
  COURSE__DELETE_SUCCESS: 'Xóa thành công',
  USER_CREATE_SUCCESS: 'Cập nhật email thành công',
  VERIFY_KEY__INPUT__INVALID: 'Mã xác thực không đúng',
  INVITATION_CODE__CODE__EXISTS: 'Mã code đã được sử dụng',
  COURSE__DELETE__SUCCESS: 'Xóa thành công',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Tạo thành công',
  USER__VERIFY_KEY__INVALID: 'Mã xác thực không đúng',
  ASSETS__INVITATION_CODE__INVALID: 'Mã code không đúng.',
  ASSETS__INVITATION_CODE__VERIFIED: 'Xác thực mã code thành công.',
  USERPROFILE__DOB__FORMAT_INVALID: 'Ngày sinh không hợp lệ',
  STAFF__CREATE__SUCCESS: 'Tạo mới nhân viên thành công',
  STAFF__DELETE__SUCCESS: 'Xóa nhân viên thành công',
  ROLE__DELETE__SUCCESS: 'Xóa nhóm thành công',
  ROLE__CREATE__SUCCESS: 'Tạo mới nhóm thành công',
  ROLE__DELETE__FAILED: 'Bạn không được xóa nhóm này',
  STAFF__INVITATION__SUCCESS: 'Gửi lời mời thành công',
  ROLE__UPDATE__SUCCESS: 'Cập nhật nhóm thành công',
  STAFF_INVITATION__INVITED__BEFORE: 'Nhân viên đã được mời trước đó',
  STAFF__UPDATE__SUCCESS: 'Cập nhật nhân viên thành công',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Tạo thành công',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Cập nhật playlist thành công',
  ASSETS__CODE__VERIFIED: 'Xác thực mã code thành công.',
  PLAYLIST__DELETE__SUCCESS: 'Xóa playlist thành công',
  PLAYLIST__UPDATE__SUCCESS: 'Cập nhật thành công Playlist',
  USER_DELETE_FAILED: 'Xóa tài khoản thất bại',
  USER_DELETE_SUCCESS: 'Xóa tài khoản thành công',
  COURSES__CODE__INVALID: 'Mã code không hợp lệ',
  CLASS__JOIN__SUCCESS: 'Tham gia lớp học thành công',
  COURSES_LESSON_EXERCISE__UPDATE__SUCCESS: 'Cập nhật câu trả lời thành công',
  COURSES_LESSON_EXERCISE__ADD__SUCCESS: 'Câu trả lời đã được gửi thành công'
};

export default message;
