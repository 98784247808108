const message = {
  mainTitle: 'General setting',
  mainTitle2: 'Notification setting',
  accountTitle: 'Change account',
  phoneTitle: 'Change phone number',
  passTitle: 'Change password',
  deleteTitle: 'Delete account',
  notiTitle: 'Notification',

  modalTitle: 'Delete account',
  modalContent: 'Your account will be delete, this action cannot be reversed'
};

export default message;
