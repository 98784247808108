const preRoute = '';

export const ROUTES = {
  DASHBOARD: `${preRoute}/`,
  ACCOUNT_INFO: `${preRoute}/account-info`,
  CLASS_LIST: `${preRoute}/class`,
  CLASS_DETAILS: `${preRoute}/class/:classID`,
  LESSON_DETAILS: `${preRoute}/class/:classID/:lessonID`,
  HISTORY_VIEW: `${preRoute}/history-view`,
  PLAYLIST: `${preRoute}/playlist`,
  SINGLE_VIDEO: `${preRoute}/video/:id`,
  FAVORITE: `${preRoute}/favorite`,
  SETTING: `${preRoute}/setting`,
  CHANGE_PASSWORD: `${preRoute}/change-password`,
  LOGIN: `${preRoute}/login`,
  REGISTER: `${preRoute}/register`,
  FORGOT_PASSWORD: `${preRoute}/forgot-password`,
  FORGOT_PASSWORD_SUCCESS: `${preRoute}/forgot-password-success`,
  FORGOT_PASSWORD_MODAL: `${preRoute}/forgot-password-modal`,
  RESET_PASSWORD_PAGE: `${preRoute}/reset-password-page`,
  SUB_USER: `${preRoute}/select-subuser`,
  ADD_SUB_USER: `${preRoute}/add-subuser`,
  NOTIFICATION: `${preRoute}/notification`,
  NOTIFICATION_DETAIL: `${preRoute}/notification/:id`,
  CALENDAR: `${preRoute}/calendar`,
  WATCH_PLAYLIST: `${preRoute}/watch-playlist/:code/:id?`
};
