const message = {
  USER__VERIFY_KEY__BLOCK: 'Too many request, please try again after 3 minutes',
  USER__ACCOUNT__INCORRECT: 'email or password incorrect',
  STUDENT__DELETE__SUCCESS: 'Delete success',
  USER__PHONE_NUMBER__REQUIRED: 'Please enter phone number',
  USER__PHONE_NUMBER__INVALID: 'Phone number is not exist',
  USER__PHONE_NUMBER__INCORRECT: 'phone number is incorrect',
  USER__VERIFY_KEY__SENDING: 'Verification code sent',
  USER__CREATE__SUCCESS: 'Sign up success',
  USER__PASSWORD__INVALID: 'Incorrect password',
  USER__EMAIL__INVALID: 'Invalid email address',
  USER__ACCOUNT__DEACTIVATE: 'Your account has been deactivated',
  USER__UPDATE__SUCCESS: 'Update success',
  USER__VERIFY_KEY__EXPIRED:
    'Your verification code has been expired, please try again',
  USER__VERIFY_KEY__INCORRECT: 'Incorrect verification code! please try again',
  USER__AVATAR__REQUIRED: 'Please update your avatar',
  ASSETS__DELETE__SUCCESS: 'Delete file successfully',
  BOOK__CREATE__SUCCESS: 'Book create successfully',
  BOOK__UPDATE__SUCCESS: 'Book update successfully',
  USER__PASSWORD__EMPTY: 'Password cannot be empty',
  USER__PASSWORD__REQUIRED: 'Password is required',
  USER__PHONE_NUMBER__UNIQUE: 'Phone number already exist',
  USER__PHONE_NUMBER__EMPTY: 'Phone number cannot be empty',
  USER__EMAIL__REQUIRED: 'Email is required',
  USER__EMAIL__LENGTH: 'Email cannot exceed 14 characters',
  USER__EMAIL__UNIQUE: 'Email already exist',
  USER__EMAIL__EMPTY: 'Email cannot be empty',
  USER__BIRTHDAY__INVALID_FORMAT: 'Invalid birthday format',
  USER__OLD_PASSWORD__EMPTY: 'Password cannot be empty',
  USER__OLD_PASSWORD__REQUIRED: 'Password is required',
  USER__NEW_PASSWORD__EMPTY: 'New password cannot be empty',
  USER__NEW_PASSWORD__REQUIRED: 'New password is required',
  USER__NEW_PASSWORD_CONFIRM__EMPTY: 'Confirm password cannot be empty',
  USER__NEW_PASSWORD_CONFIRM__REQUIRED: 'Confirm password is required',
  USER__SUID__INVALID: 'Invalid Suid',
  USER__FIELD__UNIQUE: 'Field name already exist',
  STUDENT__UPDATE__SUCCESS: 'Update success',
  STUDENT__CREATE__SUCCESS: 'Create success',
  USER_PROFILE__UPDATE__SUCCESS: 'Update success',
  WORKSPACE__DELETE__SUCCESS: 'Delete success',
  WORKSPACE__UPDATE__SUCCESS: 'Update success',
  WORKSPACE__CREATE__SUCCESS: 'Create success',
  COURSE__ADD_STUDENT__SUCCESS: 'Create success',
  COURSE__ADD_ASSETS__SUCCESS: 'Create success',
  BOOK__DELETE__SUCCESS: 'Delete success',
  COURSE__UPDATE__SUCCESS: 'Update success',
  COURSE__CREATE__SUCCESS: 'Create success',
  BOOK__INVITE_USER__SUCCESS: 'Create success',
  BOOK__ADD_ASSETS__SUCCESS: 'Create success',
  ASSETS__UPDATE__SUCCESS: 'Update success',
  ASSETS__CREATE__SUCCESS: 'Create success',
  COURSE__DELETE_SUCCESS: 'Delete success',
  USER_CREATE_SUCCESS: 'Update email successfully',
  VERIFY_KEY__INPUT__INVALID: 'Incorrect verification code! please try again',
  INVITATION_CODE__CODE__EXISTS: 'Verification code already used',
  COURSE__DELETE__SUCCESS: 'Delete success',
  ASSETS__ADD_INVITATION_CODE__SUCCESS: 'Create success',
  USER__VERIFY_KEY__INVALID: 'Invalid verification code',
  ASSETS__INVITATION_CODE__INVALID: 'Invation code invalid',
  ASSETS__INVITATION_CODE__VERIFIED: 'Invation code verified',
  USERPROFILE__DOB__FORMAT_INVALID: 'Invalid birthday format',
  STAFF__CREATE__SUCCESS: 'Create success',
  STAFF__DELETE__SUCCESS: 'Delete success',
  ROLE__DELETE__SUCCESS: 'Delete success',
  ROLE__CREATE__SUCCESS: 'Create success',
  ROLE__DELETE__FAILED: 'Delete failed',
  STAFF__INVITATION__SUCCESS: 'Invitation code sent',
  ROLE__UPDATE__SUCCESS: 'Update success',
  STAFF_INVITATION__INVITED__BEFORE: 'Invitation has been sent before',
  STAFF__UPDATE__SUCCESS: 'Update success',
  SHARE_LIST_ASSETS__CREATE__SUCCESS: 'Create success',
  SHARE_ASSETS__UPDATE__SUCCESS: 'Update playlist successfully',
  ASSETS__CODE__VERIFIED: 'Code verified',
  PLAYLIST__DELETE__SUCCESS: 'Delete playlist successfully',
  PLAYLIST__UPDATE__SUCCESS: 'Update playlist successfully',
  USER_DELETE_FAILED: 'Delete user failed',
  USER_DELETE_SUCCESS: 'Delete user successfully'
};

export default message;
