const message = {
  title: 'Sign up',
  emailSignup: 'Sign up with email',
  phoneSignup: 'Sign up with phone',
  email: 'Email',
  placeholderEmail: 'Enter email',
  password: 'Password',
  confirmPassword: 'Confirm password',
  placeholderPassword: 'Enter password',
  phoneNumber: 'Phome number',
  placeholderPhone: 'Enter phone number',
  submit: 'SUBMIT',
  registerFailed: 'Sign up failed',
  registerComplete: 'Sign up successful',
  titleChangePass: 'Change password',
  oldPassword: 'Current password',
  placeholderOldpassword: 'Enter current password',
  newPassword: 'New password',
  confirmNewPassword: 'Enter new password',
  placeholderNewpassword: 'Confirm new password',
  changePassSubmit: 'Update'
};
export default message;
