const message = {
  title: 'Login',
  emailLogin: 'Login with email',
  phoneLogin: 'Login with phone',
  pass: 'Password',
  plhPass: 'Enter password ',
  forgotPass: 'Forgot password ?',
  submit: 'LOGIN',
  errorShow: 'Email or password is incorrect, please try again',
  loginHere: 'Sign up new account',
  loginSucces: 'Login success',
  confirm: 'Confirm',
  numberPhone: 'Phone number',
  plInputNumberPhone: 'Please enter phone number',
  email: 'Email',
  rememberMe: 'Remember',
  sendAgain: 'Send again',
  enterOTPToLogin: 'Enter the OTP sent to your phone number to log in',
  expiresIn: 'Verification code expires in '
};

export default message;
