import { useCallback } from 'react';
import { useTranslation as baseTranslation } from 'react-i18next';

export default function useTranslation(namespace, basePath, ...rest) {
  const { t: baseT, ...translationRest } = baseTranslation(namespace, ...rest);
  const isValidBasePath = typeof basePath === 'string' && basePath.length > 0;
  const t = useCallback(
    (key, options) =>
      baseT(isValidBasePath ? `${basePath}.${key}` : key, options),
    [baseT, basePath, isValidBasePath]
  );

  return { t, ...translationRest };
}
