const message = {
  update: 'Update',
  verified: 'Verified',
  verifyNow: 'Verify now',
  account: 'Account',
  phoneNumber: 'Phone number',
  fullName: 'Fullname',
  birthday: 'Date of birth'
};

export default message;
