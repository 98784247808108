import request from 'services/request';

export const resetPassApi = async data =>
  request({
    url: '/reset-password',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });

export const loginApi = async data =>
  request({
    url: '/n/auth/login/',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });

export const registerApi = async data =>
  request({
    url: '/n/auth/register/',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });

export const getMeApi = async () =>
  request({
    url: '/n/auth/sub-user/',
    method: 'GET',
    isAuth: true
  });

export const getSubUserApi = async () =>
  request({
    url: '/n/auth/userinfo/',
    method: 'GET',
    isAuth: true,
    hasSubId: true
  });

export const createSubUserApi = async data =>
  request({
    url: '/n/auth/create-sub-user/',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
    isAuth: true
  });

export const changePasswordApi = async data =>
  request({
    url: '/n/auth/change-password/',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
    isAuth: true
  });

export const deleteSubUserApi = async =>
  request({
    url: `n/auth/delete-user/`,
    method: 'DELETE',
    isAuth: true,
    hasSubId: true,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });

export const getUserProfileApi = async wsname =>
  request({
    url: `/t/userprofile/`,
    method: 'GET',
    wsname
  });

export const updateProfileApi = async (wsname, data) =>
  request({
    url: '/t/userprofile/',
    method: 'PUT',
    wsname,
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const updateProfileUserApi = async data =>
  request({
    url: '/n/auth/update/',
    method: 'POST',
    data,
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const forgotApi = async data =>
  request({
    url: '/forgot',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const resetPassV2Api = async data =>
  request({
    url: '/reset-password',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const resetPassV3Api = async data =>
  request({
    url: 'reset-password-v1',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const resetPassFirst = async data =>
  request({
    url: '/reset-password-first',
    method: 'POST',
    isAuth: false,
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const logoutApi = async () =>
  request({
    url: '/logout',
    method: 'get',
    isAuth: true,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });
export const getVerifyKeyAPI = async data =>
  request({
    url: '/n/auth/login-signup-with-phone/',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const checkVerifyKeyAPI = async data =>
  request({
    url: '/n/auth/verify-login/',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const registerWidthPhone = async data =>
  request({
    url: '/n/auth/register-phone-exists/',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const updateUserAvatarApi = async data =>
  request({
    url: '/n/auth/upload/avatar/',
    method: 'POST',
    data,
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
