import styled, { css } from 'styled-components';

export const Button = styled.button`
  padding: 8px 16px !important;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.75px;
  box-sizing: border-box;
  border: 0;
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: ${({ $borderRadius }) => $borderRadius || '8px'};
  color: ${({ $color }) => $color || '#000000'};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
      padding: unset;
    `}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      width: 100%;
      max-width: ${$maxWidth};
      padding: unset;
    `}

  ${({ $size, theme }) =>
    $size === 'sm' &&
    css`
      font-size: ${theme.fontSize.base};
      height: 33px;
    `}

  ${({ $size, theme }) =>
    $size === 'base' &&
    css`
      font-size: ${theme.fontSize.base};
      height: 40px;
    `}

  ${({ $size, theme }) =>
    $size === 'md' &&
    css`
      font-size: ${theme.fontSize.md};
      height: 52px;
      padding: 12px 48px !important;
    `}

    ${({ $size, theme }) =>
    $size === 'lg' &&
    css`
      font-size: ${theme.fontSize.lg};
      height: 48px;
    `}
`;

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primaryButtonColor};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  &:active:enabled {
  }

  &:enabled:hover {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #c0c0c0;
    opacity: 0.4;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const SecondaryButton = styled(Button)`
  border: none;
  background-color: white;

  &:hover:enabled {
    opacity: 0.8;
  }

  &:focus:enabled {
    opacity: 0.8;
  }

  &:disabled {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    border: 2px solid #999999;
    color: #999999;
  }
`;
