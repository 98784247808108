const message = {
  title: 'Đăng nhập',
  emailLogin: 'Đăng nhập bằng Email',
  phoneLogin: 'Đăng nhập bằng SĐT',
  pass: 'Mật khẩu',
  plhPass: 'Nhập mật khẩu ',
  forgotPass: 'Quên mật khẩu?',
  submit: 'ĐĂNG NHẬP',
  errorShow: 'Tài khoản hoặc mật khẩu không đúng, vui lòng thử lại',
  loginHere: 'Đăng ký tài khoản mới',
  loginSucces: 'Đăng nhập thành công',
  confirm: 'Xác nhận',
  numberPhone: 'Số điện thoại',
  plInputNumberPhone: 'Vui lòng nhập số điện thoại',
  email: 'Email',
  rememberMe: 'Ghi nhớ đăng nhập',
  sendAgain: 'Gửi lại',
  enterOTPToLogin: 'Nhập mã OTP được gửi về số điện thoại của bạn để đăng nhập',
  expiresIn: 'Mã xác minh hết hạn trong '
};

export default message;
