import React, { useEffect, Suspense } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getMe, updateIsFirstAccess, getSubUser } from 'store/other/authSlice';

import { ROUTES } from 'routes/constants';

import Loading from 'components/Loading';

import webStorage from 'utils/webStorage';
import Layout from 'components/LayoutUser';

const AuthRoute = ({
  component: Component,
  hasBanner,
  hasSidebar,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isFirstAccess, isAuth } = useSelector(state => state.authReducer);

  const accessToken = webStorage.getToken();
  const isFirstLogin = localStorage.getItem('isFirstLogin');

  useEffect(() => {
    if (isFirstAccess) {
      if (accessToken) {
        dispatch(getMe());
        dispatch(getSubUser());
      } else {
        dispatch(updateIsFirstAccess());
      }
    }
  }, [accessToken, dispatch, isFirstAccess]);

  useEffect(() => {
    if (!isAuth && !isFirstAccess) window.location.replace(ROUTES.LOGIN);
  }, [isAuth, isFirstAccess]);

  useEffect(() => {
    if (accessToken && isFirstLogin === '1') history.push(ROUTES.UPDATE_EMAIL);
  }, [accessToken, history, isFirstLogin]);

  if (isFirstAccess) return <Loading />;

  return (
    <Route {...rest}>
      <Layout hasBanner={hasBanner} hasSidebar={hasSidebar}>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </Layout>
    </Route>
  );
};
export default AuthRoute;
