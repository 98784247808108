import { ROUTES } from 'routes/constants';

import iconAccount from 'assets/icons/sidebar/icon-account.svg';
import iconClass from 'assets/icons/sidebar/icon-class.svg';
import iconHistory from 'assets/icons/sidebar/icon-history.svg';
import iconPlaylist from 'assets/icons/sidebar/icon-playlist.svg';
import iconFavorite from 'assets/icons/sidebar/icon-favorite.svg';
import iconSetting from 'assets/icons/sidebar/icon-setting.svg';

export const SIDEBAR_LIST_ITEM = [
  {
    id: 1,
    name: 'accountInfo',
    link: ROUTES.ACCOUNT_INFO,
    prefixImage: iconAccount
  },
  {
    id: 2,
    name: 'class',
    link: ROUTES.CLASS_LIST,
    prefixImage: iconClass,
    number: 1
  },
  {
    id: 3,
    name: 'viewHistory',
    link: ROUTES.HISTORY_VIEW,
    prefixImage: iconHistory
  },
  {
    id: 4,
    name: 'playlist',
    link: ROUTES.PLAYLIST,
    prefixImage: iconPlaylist
  },
  {
    id: 5,
    name: 'favorite',
    link: ROUTES.FAVORITE,
    prefixImage: iconFavorite
  },
  {
    id: 6,
    name: 'setting',
    link: ROUTES.SETTING,
    prefixImage: iconSetting
  }
];
