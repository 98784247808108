const message = {
  language: 'Language',
  logIn: 'Login',
  signUp: 'Sign up',

  accountInfo: 'Account Info',
  class: 'Classes',
  viewHistory: 'History',
  playlist: 'Playlist',
  favorite: 'Favorite',
  setting: 'Setting',
  switchAccount: 'Change account',
  logout: 'Logout',
  introduce: 'Introduce',

  confirmLogout: 'Logout',
  confirmLogoutMsg: 'Are your sure you want to log out?',
  addNew: 'Add new',
  subUserTitle: 'Select account'
};

export default message;
