const validation = {
  titleForgotPassword: 'Forgot password',
  inputEmail: 'Enter email',
  inputEmailAgain: 'Enter email again',
  passwordRetrieval: 'Retrieve password',
  pleaseInputPass: 'Please enter password',
  pleaseReEnterPass: 'Please enter confirm password',
  passNoSame: 'Confirm password does not match',
  pleaseInputNewPass: 'Please enter new password',
  pleaseComfirmNewPass: 'Please confirm new password',
  pleaseInputEmail: 'Please enter email',
  pleaseInputEmailAgain: 'Please enter email again',
  pleaseInputNumberPhone: 'Plase enter phone number',
  invalidEmail: 'Email is invalid',
  pleaseInputEngName: 'Please enter english name',
  plsDateOfBirth: 'Please enter date of birth',
  plsGender: 'Please select gender',
  pleaseInputName: 'Please enter fullname',
  pleaseInputNote: 'Please enter note',
  maxName: 'Fullname cannot exceed 100 characters',
  minPass: 'Password must have atleast 6 characters',
  maxPass: 'Password cannot exceed 20 characters',
  minPhone: 'Phone number must have atleast 7 characters',
  maxPhone: 'Phone number cannot exceed 16 characters',
  maxEmail: 'Email number cannot exceed 52 characters',
  errorPhone: 'Invalid phone number',
  errorURL: 'Invalid url',
  pleaseInputURL: 'Please enter url',
  author: 'Please enter author name',
  bookName: 'Please enter book name',
  playListName: 'Please enter playlist name',
  roleName: 'Please enter group name',
  roleEmail: 'Plase enter email',
  maxString: 'Input number cannot exceed 255 characters',
  pleaseInputClassName: 'Please enter class name',
  pleaseInputClassDesc: 'Please enter description',
  pleaseInputClassTeacher: 'Please enter class teacher',
  pleaseInputNameStudent: 'Please enter student name',
  maxDes: 'Description cannot exceed 1000 characters',
  maxNameTeacher: 'Teacher name cannot exceed 1000 characters',
  maxNameCource: 'Teacher name cannot exceed 50 characters',
  plsInputNameFile: 'Please enter file name',
  plsInputTags: 'Please enter tags',
  pleaseInputAddress: 'Please enter address',
  pleaseInputWorkspace: 'Please enter wordspace name',
  roleFullName: 'Please enter fullname',
  pleaseInputWorkspaceUrl: 'Please enter workspace url',
  errorName: 'Invalid name',
  errorWorkspaceUrl: 'Invalid workspace url',
  errorWorkspace: 'Invalid workspace',
  classNameMax: 'Class name cannot exceed 50 characters',
  classDescMax: 'Class Description cannot exceed 255 characters',
  classTeacherMax: 'Teacher cannot exceed 255 characters',
  validCode: 'Please Enter code',
  invalidCode: 'Invalid QR code',
  minCodeQr: 'Code must have atleast 6 character',
  maxCodeQr: 'Code cannot exceed 6 characters'
};

export default validation;
