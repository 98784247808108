import request from 'services/request';

export const getPlayListApi = async params =>
  request({
    url: `/n/code/verify/`,
    method: 'GET',
    params
  });
export const getListCourseApi = async params =>
  request({
    url: '/n/public-courses/',
    method: 'GET',
    hasSubId: true,
    params,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: false
  });
export const getCourseDetailsApi = async id =>
  request({
    url: `/n/public-courses/${id}/`,
    method: 'GET',
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
export const getListLessonApi = async id =>
  request({
    url: `/n/public-courses/${id}/lessons/`,
    method: 'GET',
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const getLessonDetailsApi = async ({ classID, lessonID }) =>
  request({
    url: `/n/public-courses/${classID}/lessons/${lessonID}/`,
    method: 'GET',
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });

export const joinClassApi = async data =>
  request({
    url: `/n/public-courses/join-class/`,
    method: 'POST',
    data,
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: false
  });

export const answerApi = async ({ classID, lessonID }, data) =>
  request({
    url: `/n/public-courses/${classID}/lessons/${lessonID}/exercises/`,
    method: 'POST',
    data,
    hasSubId: true,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true
  });
