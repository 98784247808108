import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import media from 'styles/breakpoints';

import bg from '../../assets/image/bg.png';

export const Layout = styled.div`
  min-height: 100vh;
  background: url(${bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
`;

export const Header = styled.div`
  height: 92px;
  width: 100%;
  text-align: center;

  display: ${({ display }) => (display === 'true' ? 'block !important' : '')};
  ${media.phone`
      display: none;
  `}

  .container {
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 0;

      &--left {
        &__desktop {
          display: flex;
          align-items: center;
        }

        &__mobile {
          display: none;
        }

        ${media.phone`
        
        &__desktop {
            display: none;
          }
          &__mobile {
            display: unset;
          }
        `}

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: transparent;
          border: none;
          border-radius: 2px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 0;
          text-align: start;
        }

        .ant-select.ant-select-single.ant-select-show-arrow {
          width: 70px;
        }

        .ant-select {
          margin-left: 10px;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          box-shadow: none;
        }

        .ant-select-arrow {
          right: 8px;
          .ant-image {
            width: 16px;
          }
        }

        img {
          margin-right: 24px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 150px;

  .content__left {
    flex-shrink: 3;
  }

  .content__right {
    flex-basis: 40%;
    margin-left: 120px;
    padding-bottom: 120px;
  }

  ${media.desktop`
    margin-top: 80px;
  `}

  ${media.smallDesktop`
  justify-content: space-between;
  align-items: center;

  .content__left{
    flex-basis: 50%
  }
  .content__right{
    flex-basis: 50%
  }

  .content__right{
    margin-left:0;
  }
  `}


  ${media.phone`
  margin-top: 0;
  padding-bottom: 30px;

  .content__left{
    display: none
  }

  .content__right {
    flex-basis: 100%;
  }
  `}
`;

export const NavItem = styled(NavLink)`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;

  img {
    margin-right: 10px;
  }
`;

export const ContentWithSidebar = styled.div`
  .wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    .sidebar {
      min-width: 253px;
      height: calc(100vh - 184px);
    }
    .content {
      width: calc(100% - 253px);
      height: 100%;
      padding-left: 33px;
    }
    ${media.phone`
    .sidebar {
        display: none;
      }
      .content {
        width: 100%;
        padding-left: 0px;
      }
  `}
  }
`;

export const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  cursor: pointer;

  span {
    text-align: left;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  }

  .profile__img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  .notification {
    width: 23px;
    height: 23px;
    margin-right: 40px;
    position: relative;

    &__bell {
      width: 100%;
    }

    &__count {
      display: grid;
      place-items: center;
      font-weight: 700;
      font-size: 10px;
      line-height: 10px;
      color: #ffffff;
      width: 17px;
      height: 17px;
      background: #da281d;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
`;
