const message = {
  backButtonText: 'Tạo mới tài khoản',
  nameLabel: 'Họ và tên',
  namePlaceHolder: 'Họ và tên',
  birthdateLabel: 'Ngày sinh',
  birthdatePlaceHolder: 'dd/mm/yyyy',
  genderLabel: 'Giới tính',
  btnUpdate: 'Xác nhận',
  labelMale: 'Nam',
  labelFemale: 'Nữ'
};

export default message;
