import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import ScrollToTop from 'hooks/ScrollToTop';
import AuthRoute from '../hocs/AuthRoute';
import NonAuthRoute from '../hocs/NonAuthRoute';

import { ROUTES } from './constants';

const AccountInfo = React.lazy(() => import('containers/UserPage/AccountInfo'));
const ClassList = React.lazy(() => import('containers/UserPage/ClassList'));
const ClassDetails = React.lazy(() =>
  import('containers/UserPage/ClassDetails')
);
const LessonDetails = React.lazy(() =>
  import('containers/UserPage/LessonDetails')
);
const HistoryView = React.lazy(() => import('containers/UserPage/HistoryView'));
const Playlist = React.lazy(() => import('containers/UserPage/Playlist'));
const SingleVideo = React.lazy(() => import('containers/UserPage/SingleVideo'));
const Favorite = React.lazy(() => import('containers/UserPage/Favorite'));
const Setting = React.lazy(() => import('containers/UserPage/Setting'));
const ChangePassword = React.lazy(() =>
  import('containers/UserPage/ChangePasswordPage')
);
const DashboardPage = React.lazy(() =>
  import('containers/UserPage/DashboardPage')
);
const LoginPage = React.lazy(() => import('containers/UserPage/LoginPage'));
const RegisterPage = React.lazy(() =>
  import('containers/UserPage/RegisterPage')
);
const SubUserPage = React.lazy(() => import('containers/UserPage/SubUserPage'));
const AddUserPage = React.lazy(() => import('containers/UserPage/AddUserPage'));
const Notification = React.lazy(() =>
  import('containers/UserPage/NotificationPage')
);
const NotificationDetail = React.lazy(() =>
  import('containers/UserPage/NotificationDetailPage')
);
const Calendar = React.lazy(() => import('containers/UserPage/Calendar'));
const WatchPlayList = React.lazy(() =>
  import('containers/UserPage/WatchPlayListPage')
);

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        {/* None auth */}
        <NonAuthRoute
          hasBanner
          exact
          path={ROUTES.LOGIN}
          component={LoginPage}
        />
        <NonAuthRoute
          hasBanner
          exact
          path={ROUTES.REGISTER}
          component={RegisterPage}
        />
        <NonAuthRoute
          hasBanner
          exact
          path={ROUTES.DASHBOARD}
          component={DashboardPage}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.ACCOUNT_INFO}
          component={AccountInfo}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.CLASS_LIST}
          component={ClassList}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.CLASS_DETAILS}
          component={ClassDetails}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.LESSON_DETAILS}
          component={LessonDetails}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.HISTORY_VIEW}
          component={HistoryView}
        />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.PLAYLIST}
          component={Playlist}
        />
        <AuthRoute exact path={ROUTES.SINGLE_VIDEO} component={SingleVideo} />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.FAVORITE}
          component={Favorite}
        />
        <AuthRoute hasSidebar exact path={ROUTES.SETTING} component={Setting} />
        <AuthRoute
          hasSidebar
          exact
          path={ROUTES.CHANGE_PASSWORD}
          component={ChangePassword}
        />

        <AuthRoute
          hasBanner
          exact
          path={ROUTES.SUB_USER}
          component={SubUserPage}
        />
        <AuthRoute
          hasBanner
          exact
          path={ROUTES.ADD_SUB_USER}
          component={AddUserPage}
        />
        <AuthRoute exact path={ROUTES.NOTIFICATION} component={Notification} />
        <AuthRoute
          exact
          path={ROUTES.NOTIFICATION_DETAIL}
          component={NotificationDetail}
        />
        <NonAuthRoute
          exact
          path={ROUTES.WATCH_PLAYLIST}
          component={WatchPlayList}
        />
        <AuthRoute
          hasSidebar
          exact
          path={`${ROUTES.CALENDAR}/:classID`}
          component={Calendar}
        />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
