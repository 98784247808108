const message = {
  mainTitle: 'Chào mừng bạn đến với AHUB',
  codeInputPlaceHolder: 'Nhập mã code',
  submitCode: 'Go',
  toScanPage: 'Quét mã\nĐể Xem Ngay',
  scanTitle: 'Scan Hoặc Tải Lên QR Code Để Xem Video',
  backBtn: 'Quay lại',
  uploadBtn: 'Tải lên mã QR code',
  listClass: 'Lớp học',
  viewHistory: 'Lịch sử xem'
};

export default message;
