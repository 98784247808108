/* eslint-disable react/no-danger */
import React from 'react';
import Button from 'components/commons/Button';
import PropTypes from 'prop-types';
import * as S from './styled';
import { Text } from '../Typo';
import useTranslation from '../../../hooks/useTranslation.js';

ModalConfirm.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  onCancel: PropTypes.any,
  footer: PropTypes.any,
  set_filter: PropTypes.any,
  widthTitle: PropTypes.string,
  margin: PropTypes.string,
  changeStyle1: PropTypes.bool,
  changeStyle2: PropTypes.bool
};

function ModalConfirm({
  des,
  width,
  className,
  visible,
  set_visible,
  onCancel,
  onOk,
  widthTitle,
  margin,
  isConfirm = false,
  changeStyle1 = true,
  changeStyle2 = false,
  groupButton1 = true,
  groupButton2 = false,
  content,
  ...rest
}) {
  const { t } = useTranslation('modalConfirm');

  return (
    <S.Modal
      title=""
      width={width}
      className={className}
      open={visible}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
      $widthTitle={widthTitle}
      $margin={margin}
      centered
      {...rest}
    >
      {content && <Text textAlign="center">{content}</Text>}
      {changeStyle1 && (
        <Text textAlign="center" lineHeight="16px">
          <span dangerouslySetInnerHTML={{ __html: des }}></span>
        </Text>
      )}
      {changeStyle2 && (
        <Text lineHeight="16px" maxHeight="380px" overFlow="auto">
          <span dangerouslySetInnerHTML={{ __html: des }}></span>
        </Text>
      )}
      <S.Footer>
        {isConfirm ? (
          <Button $borderRadius="8px" $width="400px" onClick={onCancel}>
            {t('understood')}
          </Button>
        ) : (
          <>
            {groupButton1 && (
              <>
                <Button
                  $borderRadius="8px"
                  $width="171px"
                  style={{ background: 'rgba(234, 234, 234, 1)' }}
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
                <Button $borderRadius="8px" $width="171px" onClick={onOk}>
                  {t('confirm')}
                </Button>
              </>
            )}
            {groupButton2 && (
              <>
                <Button $borderRadius="8px" $width="171px" onClick={onOk}>
                  {t('agree')}
                </Button>
                <Button
                  style={{ background: 'rgba(234, 234, 234, 1)' }}
                  $borderRadius="8px"
                  $width="171px"
                  onClick={onCancel}
                >
                  {t('decline')}
                </Button>
              </>
            )}
          </>
        )}
      </S.Footer>
    </S.Modal>
  );
}

export default ModalConfirm;
