import {
  getListLessonApi,
  getCourseDetailsApi,
  getListCourseApi,
  getLessonDetailsApi
} from 'services/apis/other/course';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getListLesson = createAsyncThunk(
  'course/GET_LIST_LESSON',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListLessonApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCourseDetails = createAsyncThunk(
  'course/GET_DETAILS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getCourseDetailsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCourse = createAsyncThunk(
  'course/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const searchListCourse = createAsyncThunk(
  'course/SEARCH_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getLessonDetails = createAsyncThunk(
  'course/GET_LESSON_DETAILS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getLessonDetailsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  courseCount: 0,
  courseDetails: {},
  lessonDetails: {},
  listCourse: [],
  listCourseSearch: [],
  listLesson: [],
  loading: false,
  error: {}
};

const courseSlice = createSlice({
  name: 'course',
  initialState,

  reducers: {
    resetCourseDetails: state => {
      state.courseDetails = {};
    }
  },

  extraReducers: {
    [getListCourse.pending]: state => {
      state.loading = true;
    },
    [getListCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourse.fulfilled]: (state, action) => {
      state.listCourse = action.payload.results;
      state.courseCount = action.payload.results.length;
      state.loading = false;
    },

    [searchListCourse.pending]: state => {
      state.loading = true;
    },
    [searchListCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [searchListCourse.fulfilled]: (state, action) => {
      state.listCourseSearch = action.payload.results;
      state.loading = false;
    },

    [getCourseDetails.pending]: state => {
      state.loading = true;
    },
    [getCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getCourseDetails.fulfilled]: (state, action) => {
      state.courseDetails = action.payload;
      state.loading = false;
    },

    [getListLesson.pending]: state => {
      state.loading = true;
    },
    [getListLesson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListLesson.fulfilled]: (state, action) => {
      state.listLesson = action.payload.results;
      state.loading = false;
    },

    [getLessonDetails.pending]: state => {
      state.loading = true;
    },
    [getLessonDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getLessonDetails.fulfilled]: (state, action) => {
      state.lessonDetails = action.payload;
      state.loading = false;
    }
  }
});

export const { resetCourseDetails } = courseSlice.actions;
const { reducer: courseReducer } = courseSlice;
export default courseReducer;
