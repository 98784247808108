import React from 'react';
import styled from 'styled-components';

import { Spin, Space } from 'antd';

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: ${({ $height }) => $height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = ({ $height = '100vh', $size = 'large' }) => (
  <SpinnerWrapper $height={$height}>
    <Space>
      <Spin size={$size} />
    </Space>
  </SpinnerWrapper>
);

export default Loading;
