const message = {
  backButtonText: 'Create new account',
  nameLabel: 'Fullname',
  namePlaceHolder: 'Enter fullname',
  birthdateLabel: 'Date of birth',
  birthdatePlaceHolder: 'dd/mm/yyyy',
  genderLabel: 'Gender',
  btnUpdate: 'Confirm',
  labelMale: 'Male',
  labelFemale: 'Female'
};

export default message;
