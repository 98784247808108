import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 19px 0px 2px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ListMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const Item = styled.div`
  width: 100%;
  height: 50px;
  padding: 16px;

  background: ${({ active }) => (!active ? '' : 'rgba(0,0,0,0.04)')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  transition: all 0.3s ease-in-out;

  img {
    cursor: pointer;
  }

  span {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: ${({ active }) => (!active ? '#000000' : '#a52a0e')};
    &:hover {
      color: #a52a0e;
    }
  }

  .number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 20px;
    height: 20px;
    background: #da281d;
    border-radius: 100px;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
  }
`;

export const Exit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 16px;

  img {
    cursor: pointer;
  }

  span {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #666666;

    &:hover {
      color: #000000;
    }
  }
`;
