import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import { ROUTES } from 'routes/constants';
import {
  API_SERVER,
  TOKEN_EXPIRED_STATUS_CODE,
  SUB_ID
} from '../constants/configs';
import ToastMessage from '../components/Toast';
import webStorage from '../utils/webStorage';

const baseApiConfig = {
  baseURL: `${API_SERVER}/v1/`,
  headers: {
    'content-type': 'multipart/form-data' && 'application/json'
  },
  timeout: 600000, // 600s = 10 mins
  paramsSerializer: params => queryString.stringify(params)
};

const request = ({
  enableFlashMessageError = true,
  enableFlashMessageSuccess = false,
  isAuth = true,
  hasSubId = false,
  wsname = null,
  ...options
}) => {
  const baseApiClient = axios.create(baseApiConfig);
  if (isAuth) {
    const accessToken = webStorage.getToken();
    if (accessToken)
      baseApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    if (hasSubId) {
      const subid = localStorage.getItem(SUB_ID);
      if (subid) {
        baseApiClient.defaults.headers.common.suid = subid;
      }
    }
    if (wsname) baseApiClient.defaults.headers.common.TENANT = wsname;
  }

  const onSuccess = response => {
    if (enableFlashMessageSuccess && response?.data?.message) {
      const messageList = [];
      messageList.push(response.data.message);
      toast(
        <ToastMessage
          type="success"
          message={messageList}
          translation="messageStatus"
        />
      );
    }
    return Promise.resolve(response);
  };

  const onError = async error => {
    if (
      error.response.status !== TOKEN_EXPIRED_STATUS_CODE &&
      enableFlashMessageError &&
      error.response?.data?.errors
    ) {
      const messageList = [];
      const errData = error.response.data;
      if (errData.errors && errData.errors.includes('Validation')) {
        Object.values(errData.errors).forEach(errArr => {
          errArr.forEach(err => {
            messageList.push(err);
          });
        });
      } else messageList.push(errData.errors);
      toast(
        <ToastMessage
          type="error"
          message={messageList}
          translation="messageStatus"
        />
      );
    }

    if (
      error.response.status !== TOKEN_EXPIRED_STATUS_CODE &&
      enableFlashMessageError &&
      error.response?.data?.message
    ) {
      const messageList = [];
      const errData = error.response.data;
      messageList.push(errData.message);
      toast(
        <ToastMessage
          type="error"
          message={messageList}
          translation="messageStatus"
        />
      );
    }

    if (error.response.status === TOKEN_EXPIRED_STATUS_CODE) {
      const originalRequest = error.config;
      const refreshToken = webStorage.getRefreshToken();
      if (refreshToken) {
        try {
          const res = await axios
            .create(baseApiConfig)
            .post('/n/token/refresh/', { refresh: refreshToken });

          webStorage.setToken(res?.data?.access, { expires: 30 });
          originalRequest.headers.Authorization = `Bearer ${res?.data?.access}`;
          return baseApiClient(originalRequest);
        } catch (_error) {
          webStorage.removeAll();
          window.location.replace(ROUTES.LOGIN);
        }
      } else {
        webStorage.removeAll();
        window.location.replace(ROUTES.LOGIN);
      }
    }
    return Promise.reject(error.response);
  };

  return baseApiClient(options).then(onSuccess).catch(onError);
};

export default request;
