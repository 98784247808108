const message = {
  update: 'Cập nhật',
  verified: 'Đã xác minh',
  verifyNow: 'Xác minh ngay',
  account: 'Tài khoản',
  phoneNumber: 'Số điện thoại',
  fullName: 'Họ và tên',
  birthday: 'Ngày sinh',
  accountInfo: 'Thông tin tài khoản'
};

export default message;
