import {
  loginApi,
  registerApi,
  getMeApi,
  getSubUserApi,
  checkVerifyKeyAPI,
  getVerifyKeyAPI,
  getUserProfileApi
} from 'services/apis/other/auth';
import webStorage from 'utils/webStorage';

import { ROUTES } from 'routes/constants';
import { SUB_ID } from 'constants/configs';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const login = createAsyncThunk(
  'auth/LOGIN',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loginApi(data);
      return { ...res.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVerifyKey = createAsyncThunk(
  'auth/GET_VERIFY_KEY',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getVerifyKeyAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkVerifyKey = createAsyncThunk(
  'auth/VERIFY',
  async (data, { rejectWithValue }) => {
    try {
      const res = await checkVerifyKeyAPI(data);
      return { ...res.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const register = createAsyncThunk(
  'auth/REGISTER',
  async (data, { rejectWithValue }) => {
    try {
      const res = await registerApi(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMe = createAsyncThunk('auth/GET_ME', async () => {
  const response = await getMeApi();
  return response.data;
});

export const getSubUser = createAsyncThunk('auth/GET_SUBUSER', async () => {
  const response = await getSubUserApi();
  return response.data;
});

export const getUserProfile = createAsyncThunk(
  'auth/GET_USER_PROFILE',
  async (wsname, { rejectWithValue }) => {
    try {
      const res = await getUserProfileApi(wsname);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const initialState = {
  currentUsers: [],
  currentUser: {},
  userProfile: {},
  isAuth: false,
  isFirstAccess: true,
  loading: false,
  error: {},
  isRemember: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    logOut: state => {
      state.isAuth = false;
      state.currentUsers = {};
      webStorage.removeAll();
      localStorage.removeItem(SUB_ID);
      window.location.replace(ROUTES.LOGIN);
    },
    updateIsFirstAccess: state => {
      state.isFirstAccess = false;
    },
    resetState: (state, action) => {
      const fieldName = action.payload;
      state[fieldName] = initialState[fieldName];
    },

    setIsRemember: (state, action) => {
      state.isRemember = action.payload;
    },
    selectUser: (state, action) => {
      state.currentUser = action.payload;
      localStorage.setItem(SUB_ID, action.payload?.id);
    },
    removeSubUserAction: state => {
      state.currentUser = {};
    }
  },

  extraReducers: {
    [login.pending]: state => {
      state.loading = true;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.isFirstAccess = false;
      state.error = action.payload?.message;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.isFirstAccess = true;
      state.loading = false;
      state.error = {};
      webStorage.setToken(
        action.payload.access_token,
        state.isRemember ? { expires: 30 } : {}
      );
      webStorage.setRefreshToken(
        action.payload.refresh_token,
        state.isRemember ? { expires: 30 } : {}
      );
    },
    [checkVerifyKey.pending]: state => {
      state.loading = true;
    },
    [checkVerifyKey.rejected]: (state, action) => {
      state.loading = false;
      state.isFirstAccess = false;
      state.error = action.payload?.message;
    },
    [checkVerifyKey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      webStorage.setToken(action.payload?.token?.access_token, {});
      webStorage.setRefreshToken(action.payload?.token?.refresh_token, {});
      state.isAuth = true;
      if (
        action?.payload?.user?.default_email === true ||
        action?.payload?.user?.has_password === false
      ) {
        localStorage.setItem('isFirstLogin', '1');
      }
    },

    [getMe.pending]: state => {
      state.loading = true;
    },
    [getMe.rejected]: state => {
      state.loading = false;
      state.isAuth = false;
      state.isFirstAccess = false;
    },
    [getMe.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.isFirstAccess = false;
      state.currentUsers = action.payload;
      state.loading = false;
    },
    [getSubUser.pending]: state => {
      state.loading = true;
    },
    [getSubUser.rejected]: state => {
      state.loading = false;
    },
    [getSubUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
    },
    [getUserProfile.pending]: state => {
      state.loading = true;
    },
    [getUserProfile.rejected]: state => {
      state.loading = false;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.userProfile = action.payload?.data;
      state.loading = false;
    },

    [getVerifyKey.pending]: state => {
      state.loading = true;
    },
    [getVerifyKey.rejected]: state => {
      state.loading = false;
    },
    [getVerifyKey.fulfilled]: state => {
      state.loading = false;
    }
  }
});

export const {
  updateIsFirstAccess,
  setIsRemember,
  setAuth,
  logOut,
  selectUser,
  removeSubUserAction
} = authSlice.actions;
const { reducer: authReducer } = authSlice;
export default authReducer;
