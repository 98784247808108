const message = {
  home: 'Trang chủ',
  studyForTest: 'Luyện thi',
  listQuestions: 'Tất cả câu hỏi',
  competition: 'Cuộc thi',
  donors: 'Nhà tài trợ',
  policy: 'Chính sách',
  aboutUs: 'Về chúng tôi',
  contact: 'Liên hệ',
  login: 'Đăng nhập',
  new: 'Tin tức',

  accountInfo: 'Thông tin tài khoản',
  class: 'Lớp học',
  viewHistory: 'Lịch sử xem',
  playlist: 'Playlist',
  favorite: 'Yêu thích',
  setting: 'Cài đặt',
  switchAccount: 'Đổi tài khoản',
  logout: 'Đăng xuất',
  introduce: 'Giới thiệu',

  confirmLogout: 'Đăng xuất',
  confirmLogoutMsg: 'Bạn có chắc bạn muốn đăng xuất?',
  addNew: 'Tạo mới',
  subUserTitle: 'Chọn tài khoản sử dụng',

  language: 'Ngôn ngữ',
  logIn: 'Đăng nhập',
  signUp: 'Đăng ký'
};

export default message;
