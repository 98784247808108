import { LANGUAGE } from 'constants/configs';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  language: localStorage.getItem(LANGUAGE) || 'vi'
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,

  reducers: {
    changeLanguageAction: (state, action) => {
      state.language = action.payload;
    }
  }
});

export const { changeLanguageAction } = layoutSlice.actions;
const { reducer: layoutReducer } = layoutSlice;
export default layoutReducer;
