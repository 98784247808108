import { configureStore } from '@reduxjs/toolkit';
import authReducer from './other/authSlice';
import userOverviewReducer from './user/overviewSlice';
import layoutReducer from './other/layoutSlice';
import courseReducer from './user/courseSlice';

export const store = configureStore({
  reducer: {
    layoutReducer,
    authReducer,
    userOverviewReducer,
    courseReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
