const message = {
  title: 'Đăng ký',
  emailSignup: 'Đăng ký bằng Email',
  phoneSignup: 'Đăng ký bằng SĐT',
  email: 'Email',
  placeholderEmail: 'Nhập email',
  password: 'Mật khẩu',
  confirmPassword: 'Nhập lại Mật khẩu',
  placeholderPassword: 'Nhập mật khẩu',
  placeholderConfirmPassword: 'Nhập lại mật khẩu',
  submit: 'ĐĂNG KÝ',
  registerFailed: 'Đăng ký thất bại',
  registerComplete: 'Đăng ký thành công',
  titleChangePass: 'Đổi mật khẩu',
  oldPassword: 'Mật khẩu cũ',
  placeholderOldpassword: 'Nhập mật khẩu cũ',
  newPassword: 'Mật khẩu mới',
  confirmNewPassword: 'Nhập lại mật khẩu mới',
  placeholderNewpassword: 'Nhập lại mật khẩu mới',
  changePassSubmit: 'Cập nhật'
};
export default message;
