const validation = {
  titleForgotPassword: 'Quên mật khẩu',
  inputEmail: 'Nhập email',
  inputEmailAgain: 'Nhập lại email',
  passwordRetrieval: 'Lấy lại mật khẩu',
  pleaseInputPass: 'Vui lòng nhập mật khẩu',
  pleaseReEnterPass: 'Vui lòng nhập lại mật khẩu',
  passNoSame: 'Mật khẩu xác nhận không khớp',
  pleaseInputNewPass: 'Vui lòng nhập mật khẩu mới',
  pleaseComfirmNewPass: 'Vui lòng xác nhận mật khẩu mới',
  pleaseInputEmail: 'Vui lòng nhập email',
  pleaseInputEmailAgain: 'Vui lòng nhập Email chính xác',
  pleaseInputNumberPhone: 'Vui lòng nhập số điện thoại',
  invalidEmail: 'Email không hợp lệ',
  pleaseInputEngName: 'Vui lòng nhập tên tiếng anh',
  plsDateOfBirth: 'Vui lòng nhập ngày sinh',
  plsGender: 'Vui lòng chọn giới tính',
  pleaseInputName: 'Vui lòng nhập họ và tên',
  pleaseInputNote: 'Vui lòng nhập ghi chú',
  maxName: 'Họ và tên có tối đa 100 ký tự',
  minPass: 'Mật khẩu có ít nhất 6 ký tự',
  maxPass: 'Mật khẩu có tối đa 20 ký tự',
  minPhone: 'Số điện thoại có ít nhất 7 ký tự',
  maxPhone: 'Số điện thoại có tối đa 16 ký tự',
  maxEmail: 'Email có tối đa 52 ký tự',
  errorPhone: 'Số điện thoại không đúng định dạng',
  errorURL: 'Sai định dạng link',
  pleaseInputURL: 'Vui lòng nhập URL',
  author: 'Vui lòng nhập tên tác giả',
  bookName: 'Vui lòng nhập tên sách',
  playListName: 'Vui lòng nhập tên PlayList',
  roleName: 'Vui lòng nhập tên nhóm',
  roleEmail: 'Vui lòng nhập email',
  maxString: 'Vui lòng không nhập quá 255 ký tự',
  pleaseInputClassName: 'Vui lòng nhập tên lớp học',
  pleaseInputClassDesc: 'Vui lòng nhập mô tả',
  pleaseInputClassTeacher: 'Vui lòng nhập tên giáo viên',
  pleaseInputNameStudent: 'Vui lòng nhập tên',
  maxDes: 'Mô tả nhập tối đa 1000 ký tự',
  maxNameTeacher: 'Tên giáo viên tối đa 100 ký tự',
  maxNameCource: 'Tên lớp học tối đa 50 ký tự',
  plsInputNameFile: 'Vui lòng nhập tên tập tin',
  plsInputTags: 'Vui làm nhập thẻ tập tin',
  pleaseInputAddress: 'Vui lòng nhập địa chỉ',
  pleaseInputWorkspace: 'Vui lòng nhập tên Workspace',
  roleFullName: 'Vui lòng nhập họ tên',
  pleaseInputWorkspaceUrl: 'Vui lòng nhập URL của Workspace',
  errorName: 'Vui lòng nhập họ và tên hợp lệ',
  errorWorkspaceUrl: 'Sai định dạng URL của Workspace',
  errorWorkspace: 'Sai định dạng Workspace',
  classNameMax: 'Vui lòng không nhập quá 50 ký tự',
  classDescMax: 'Vui lòng không nhập quá 255 ký tự',
  classTeacherMax: 'Vui lòng không nhập quá 255 ký tự',
  validCode: 'Yêu cầu nhập mã code',
  invalidCode: 'Mã code không hợp lệ',
  minCodeQr: 'Mã code có ít nhất 6 ký tự',
  maxCodeQr: 'Mã code có tối đa 6 ký tự'
};

export default validation;
