import styled from 'styled-components';
import { Modal as BaseModal } from 'antd';

export const Modal = styled(BaseModal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  svg {
    color: #ffffff;
  }
  .ant-modal-title {
    text-transform: capitalize;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-style: bold;
    text-align: center;
    font-weight: 700;
    width: ${({ $widthTitle }) => $widthTitle || 'auto'};
    margin: ${({ $margin }) => $margin || 'auto'};
  }
  .ant-modal-body {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
    }
  }
  .ant-modal-title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #ffffff;
    font-style: bold;
    text-align: center;
  }
  .ant-modal-header {
    margin: 0px !important;
    background-color: #000000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: none;
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 24px;
`;
