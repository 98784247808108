import React, { useState } from 'react';
import { Select, Drawer, Image } from 'antd';
import { generatePath, useHistory, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import { changeLanguageAction } from 'store/other/layoutSlice';

import useTranslation from 'hooks/useTranslation.js';

import { ROUTES } from 'routes/constants';

import { Text } from 'components/commons/Typo';
// import Button from 'components/commons/Button';
import Loading from 'components/Loading';
import Sidebar from './Sidebar';

import logo from 'assets/image/logo.svg';
import vn from 'assets/icons/flag-vn.svg';
import en from 'assets/icons/flag-en.svg';
import lock from 'assets/icons/lock-login.svg';
import globe from 'assets/icons/globe-icon.svg';
import person from 'assets/icons/person-icon.svg';
import bell from 'assets/icons/bell-icon.svg';
import menu from 'assets/icons/menu-mobile.svg';
import bannerImg from 'assets/image/man-jumping.svg';
import user1 from 'assets/image/subUser-ava1.svg';
import arrowDown from 'assets/icons/icon-arrow-down.svg';

import * as S from './styled';

export default function LayoutUser({ children, hasBanner, hasSidebar }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation('layoutUser');

  const { isAuth, currentUser } = useSelector(state => state.authReducer);
  const { language } = useSelector(state => state.layoutReducer);

  const [open, setOpen] = useState(false);

  return (
    <S.Layout>
      <S.Header
        display={history.location.pathname === ROUTES.DASHBOARD ? 'true' : ''}
      >
        <div className="container">
          <div className="content">
            <div className="content--left">
              <div className="content--left__desktop">
                <img
                  src={logo}
                  alt=""
                  onClick={() => {
                    history.push(generatePath(ROUTES.HOME));
                  }}
                />
                <Text $fontWeight="600" $fontSize="16px" $lineHeight="24px">
                  {t('language')}
                </Text>

                <Select
                  suffixIcon={
                    <Image src={arrowDown} alt="arrowDown" preview={false} />
                  }
                  defaultValue={language}
                  onChange={lang => {
                    i18n.changeLanguage(lang);
                    dispatch(changeLanguageAction(lang));
                  }}
                  options={[
                    {
                      value: 'vi',
                      label: <img src={vn} alt="" />
                    },
                    {
                      value: 'en',
                      label: <img src={en} alt="" />
                    }
                  ]}
                ></Select>
              </div>
              <div className="content--left__mobile">
                <img src={menu} alt="" onClick={() => setOpen(true)} />
              </div>
            </div>

            {/* // hidden login*/}

            {/* {!isAuth && (
              <Button
                $type="secondary"
                size="base"
                onClick={() => {
                  history.push(generatePath(ROUTES.LOGIN));
                }}
              >
                <img src={lock} alt="" /> {t('logIn')}
              </Button>
            )} */}

            {isAuth && Object.keys(currentUser).length > 0 && (
              <S.AccountInfo>
                <div
                  className="notification"
                  onClick={() =>
                    history.push(generatePath(ROUTES.NOTIFICATION))
                  }
                >
                  <img src={bell} className="notification__bell" alt="bell" />
                  <span className="notification__count">3</span>
                </div>
                <img
                  src={currentUser?.picture || user1}
                  alt=""
                  className="profile__img"
                  onClick={() =>
                    history.push(generatePath(ROUTES.ACCOUNT_INFO))
                  }
                />
                <Tooltip
                  title={
                    currentUser?.first_name ||
                    currentUser?.phone_number ||
                    currentUser?.email
                  }
                >
                  <span
                    onClick={() =>
                      history.push(generatePath(ROUTES.ACCOUNT_INFO))
                    }
                  >
                    {currentUser?.first_name ||
                      currentUser?.phone_number ||
                      currentUser?.email}
                  </span>
                </Tooltip>
              </S.AccountInfo>
            )}
          </div>
        </div>
      </S.Header>
      {hasBanner ? (
        <S.Content className="container">
          <div className="content__left">
            <img src={bannerImg} alt="man jumping" />
          </div>
          <div className="content__right">{children}</div>
        </S.Content>
      ) : hasSidebar ? (
        <S.ContentWithSidebar className="container">
          <div className="wrapper">
            <div className="sidebar">
              <Sidebar />
            </div>
            <div className="content">{children}</div>
          </div>
        </S.ContentWithSidebar>
      ) : (
        <div className="container">{children}</div>
      )}

      <Drawer
        onClose={() => setOpen(false)}
        open={open}
        placement="left"
        className="drawer"
      >
        {!isAuth ? (
          <>
            <NavLink
              to="/login"
              className={isActive => (isActive ? 'menu-selected' : '')}
              onClick={() => setOpen(false)}
            >
              <img src={lock} alt="" /> <p>{t('logIn')}</p>
            </NavLink>
            <NavLink
              to="/register"
              className={isActive => (isActive ? 'menu-selected' : '')}
              onClick={() => setOpen(false)}
            >
              <img src={person} alt="" /> <p>{t('signUp')}</p>
            </NavLink>
            <NavLink
              to="/"
              exact={true}
              className={isActive => (isActive ? 'menu-selected' : '')}
              onClick={() => setOpen(false)}
            >
              <img src={globe} alt="" /> <p> {t('language')}</p>
            </NavLink>
          </>
        ) : (
          <div className="sidebarPhone">
            <div className="userInfo">
              <div className="avatar">
                {currentUser?.picture ? (
                  <Image src={currentUser?.picture} />
                ) : (
                  <Loading size="nomal" height="100%" />
                )}
              </div>
              <div className="name">
                <span>{currentUser?.first_name}</span>
              </div>
            </div>
            <Sidebar />
          </div>
        )}
      </Drawer>
    </S.Layout>
  );
}
