import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Image } from 'antd';

import { logOut } from 'store/other/authSlice';
import { getListCourse } from 'store/user/courseSlice';

import useTranslation from 'hooks/useTranslation';

import ModalConfirm from 'components/commons/ModalConfirm';

import iconLogout from 'assets/icons/sidebar/icon-logout.svg';

import * as S from './styled';

import { SIDEBAR_LIST_ITEM } from './mock-data';

function Sidebar() {
  const { t } = useTranslation('layoutUser');

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { courseCount } = useSelector(state => state.courseReducer);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getListCourse());
  }, [location.pathname, dispatch]);

  return (
    <S.Wrapper>
      <S.ListMenu>
        {SIDEBAR_LIST_ITEM.map(item => (
          <S.Item
            key={item?.id}
            active={location.pathname.includes(item?.link)}
          >
            <Image src={item?.prefixImage} alt={item?.name} preview={false} />
            <span
              onClick={() => {
                history.push(generatePath(item?.link));
              }}
            >
              {t(item?.name)}
            </span>
            {item?.number && <div className="number">{courseCount}</div>}
          </S.Item>
        ))}
      </S.ListMenu>
      <S.Exit
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Image src={iconLogout} alt="iconLogout" preview={false} />
        <span>{t('logout')}</span>
      </S.Exit>
      <ModalConfirm
        title={t('confirmLogout')}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        des={`<b>${t('confirmLogoutMsg')}</b>`}
        onOk={() => dispatch(logOut())}
      />
    </S.Wrapper>
  );
}

export default Sidebar;
