import layout from 'components/Layout/locales/vi';
import layoutUser from 'components/LayoutUser/locales/vi';
import loginPage from 'containers/UserPage/LoginPage/locales/vi';
import registerPage from 'containers/UserPage/RegisterPage/locales/vi';
import modalConfirm from 'components/commons/ModalConfirm/locales/vi';
import modal from 'components/commons/Modal/locales/vi';
import messageStatus from './message/vi';
import validation from './validation/vi';
import addSubUserPage from 'containers/UserPage/AddUserPage/locales/vi';
import subUserPage from 'containers/UserPage/SubUserPage/locales/vi';
import accountInfoPage from 'containers/UserPage/AccountInfo/locales/vi';
import classListPage from 'containers/UserPage/ClassList/locales/vi';
import search from 'components/commons/Search/locales/vi';
import accountSettingPage from 'containers/UserPage/Setting/locales/vi';
import classDetailsPage from 'containers/UserPage/ClassDetails/locales/vi';
import homePage from 'containers/UserPage/DashboardPage/locales/vi';
import lessonDetailsPage from 'containers/UserPage/LessonDetails/locales/vi';
import notiPage from 'containers/UserPage/NotificationPage/locales/vi';
import favoritePage from 'containers/UserPage/Favorite/locales/vi';
import historyViewPage from 'containers/UserPage/HistoryView/locales/vi';
import calendarPage from 'containers/UserPage/Calendar/locales/vi';
import watchPlayListPage from 'containers/UserPage/WatchPlayListPage/locales/vi';
import playlistPage from 'containers/UserPage/Playlist/locales/vi';

const locales = {
  layout,
  layoutUser,
  loginPage,
  registerPage,
  homePage,
  addSubUserPage,
  subUserPage,
  accountSettingPage,
  modalConfirm,
  validation,
  modal,
  messageStatus,
  accountInfoPage,
  classListPage,
  search,
  classDetailsPage,
  lessonDetailsPage,
  notiPage,
  favoritePage,
  historyViewPage,
  calendarPage,
  watchPlayListPage,
  playlistPage
};

export default locales;
