const message = {
  schedule: 'Lịch học',
  QA: 'Hỏi đáp',
  listLesson: 'Danh sách buổi học',
  lesson: 'Buổi học',
  readMore: 'Đọc tiếp',
  showLess: 'Ẩn bớt',
  January: 'Tháng 1',
  February: 'Tháng 2',
  March: 'Tháng 3',
  April: 'Tháng 4',
  May: 'Tháng 5',
  June: 'Tháng 6',
  July: 'Tháng 7',
  August: 'Tháng 8',
  September: 'Tháng 9',
  October: 'Tháng 10',
  November: 'Tháng 11',
  December: 'Tháng 12',
  lessosIsBlocking: 'Lớp học đã bị huỷ',
  Monday: 'Thứ 2',
  Tuesday: 'Thứ 3',
  Wednesday: 'Thứ 4',
  Thursday: 'Thứ 5',
  Friday: 'Thứ 6',
  Saturday: 'Thứ 7',
  Sunday: 'Chủ Nhật',
  scheduled: 'Lên lịch',
  cancelled: 'Đã huỷ',
  finished: 'Đã hoàn thành',
  publish: 'Đang diễn ra'
};

export default message;
