const message = {
  all: 'Tất cả',
  joined: 'Đã tham gia',
  finished: 'Đã hoàn thành',
  unfinished: 'Chưa hoàn thành',
  totalClass: 'Lớp học',
  findClass: 'Tìm kiếm lớp học',
  noData: 'Danh sách trống',
  noDataFor: 'Không có kết quả tìm kiếm cho '
};

export default message;
