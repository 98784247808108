import React from 'react';
import ReactDOM from 'react-dom';

// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';

import App from './App';
// import reportWebVitals from './reportWebVitals';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCycT-hBhJu1KoctA76RYIO2C3wsUxTiVI',
//   authDomain: 'ahub-kids.firebaseapp.com',
//   projectId: 'ahub-kids',
//   storageBucket: 'ahub-kids.appspot.com',
//   messagingSenderId: '787913040119',
//   appId: '1:787913040119:web:4e1bf552ec114adca233f2',
//   measurementId: 'G-Z9T12QZ54B',
// };
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(analytics);
