const message = {
  notificationContent:
    'Bạn còn <span>{{numberUnfinishedExercises}}</span> bài tập chưa hoàn thành',
  totalScore: 'Tổng điểm hiện tại của bạn là',
  attachFile: 'File đính kèm',
  QA: 'Hỏi đáp',
  lessonContent: 'Nội dung học',
  excercise: 'Bài tập',
  yourAnswer: 'Trả lời',
  theAnswerIsGrading: 'Giáo viên đang chấm điểm',
  theAnswerIsEmpty: 'Câu trả lời đang để trống'
};

export default message;
