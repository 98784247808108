const message = {
  mainTitle: 'Cài đặt chung',
  mainTitle2: 'Cài đặt thông báo',
  accountTitle: 'Đổi tài khoản',
  phoneTitle: 'Đổi số điện thoại',
  passTitle: 'Đổi mật khẩu',
  deleteTitle: 'Xóa tài khoản',
  notiTitle: 'Nhận thông báo',
  setting: 'Cài đặt',
  modalTitle: 'Xóa tài khoản',
  modalContent: 'Tài khoản của bạn sẽ bị xóa và không thể phục hồi'
};

export default message;
