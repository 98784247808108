import React, { useEffect, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMe, updateIsFirstAccess, getSubUser } from 'store/other/authSlice';

import Loading from 'components/Loading';

import PropTypes from 'prop-types';
import webStorage from 'utils/webStorage';
import Layout from 'components/LayoutUser';

NonAuthRoute.propTypes = {
  component: PropTypes.any
};

function NonAuthRoute({
  component: Component,
  hasBanner,
  hasSidebar,
  ...rest
}) {
  const dispatch = useDispatch();
  const isFirstAccess = useSelector(state => state.authReducer.isFirstAccess);

  useEffect(() => {
    if (isFirstAccess) {
      const accessToken = webStorage.getToken();
      if (accessToken) {
        dispatch(getMe());
        dispatch(getSubUser());
      } else {
        dispatch(updateIsFirstAccess());
      }
    }
  }, [dispatch, isFirstAccess]);

  if (isFirstAccess) return <Loading />;

  return (
    <Route {...rest}>
      <Layout hasBanner={hasBanner} hasSidebar={hasSidebar}>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </Layout>
    </Route>
  );
}

export default NonAuthRoute;
